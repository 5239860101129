body {
  background-image: url('../../assets/bg.png');

  .container {
    max-width: 1000px;
  }

  h1 {
    @apply text-3xl mb-2;
  }

  h2 {
    @apply text-2xl mb-2;
  }

  h3 {
    @apply text-lg mb-2;
  }

  h4 {
    @apply text-base mb-2;
  }

  p {
    @apply my-3;
  }

  a {
    @apply text-blue-400 underline;
  }

  blockquote {
    @apply border-l-2 pl-4 text-slate-600 border-slate-400 my-5 block;
  }

  .bottom-links {
    @apply flex;

    li {
      @apply mr-6;
    }

    .icon svg {
      @apply inline;
      @apply text-gray-500;

      height: 24px;
      width: 24px;
    }

    a {
      @apply text-gray-700;
    }

    a:hover {
      @apply text-blue-400;
    }
  }
}
